
import './App.css';

const Line = () => {return <div style={{'width':'80%'}}><hr style={{'height':'3px','background-color':'#ffffff', 'border':'none'}}/></div>}

function App() {

  return (
    <div className="App">
      <header className="App-header">
          <span style={{'fontSize':'3em'}}>When's Mana Quitting?</span>
          <Line/>
          <span style={{'fontSize':'3em'}}>🥳 Today's the day! 🎉</span>
      </header>
    </div>
  );
}

export default App;
